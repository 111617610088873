import classNames from 'classnames';
import React, { CSSProperties, useEffect } from 'react';
import { ButtonGroup, Checkbox, CheckboxGroup, Dropdown, InputGroup } from 'rsuite';
import CustomNumberInput from '../../../../../../components/CustomNumberInput/CustomNumberInput';
import { PriceVariationFilterProps, PriceVariationValueFilterType } from '../../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { BaseQuickActionFilterProps } from '../QuickActionFilter.types';
import styles from './FilterPriceVariation.module.scss';
import { MdKeyboardArrowDown } from './Icon/assets';
import { IconButton } from './IconButton';

const OPTIONS = [
    {
        id: 1,
        label: 'Produtos com aumento de preço',
        value: 'POSITIVE',
    },
    {
        id: 2,
        label: 'Produtos com redução de preço',
        value: 'NEGATIVE',
    },
];

const SELECTOR = [
    { label: 'Maior ou igual', value: 'HIGHEST' },
    { label: 'Menor ou igual', value: 'LOWEST' },
    { label: 'Entre', value: 'BETWEEN' },
];
type FilterPriceVariationProps = {
    icon?: React.ReactNode;
    unitLabel?: string;
    menuStyle: CSSProperties;
    defaultValue?: string;
    placeholder?: string;
    state: PriceVariationFilterProps;
    onClose: () => void;
    onOpen: () => void;
    onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
    onChangeValue: (paramName: keyof PriceVariationFilterProps, value: any) => void;
    onSelect?: (eventKey: any, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const FilterPriceVariation = ({ icon, menuStyle, placeholder, onClick, onClose, onOpen, state, onChangeValue }: FilterPriceVariationProps) => {
    const [menuStyleState, setMenuStyleState] = React.useState(menuStyle);
    const [open, setOpen] = React.useState(false);

    const inputValueRef = React.useRef<HTMLInputElement>(null);
    const inputMinValueRef = React.useRef<HTMLInputElement>(null);

    const skinColor: BaseQuickActionFilterProps['skin'] = state.isActive || open ? 'primary' : 'neutral';

    useEffect(() => {
        if (!open) return;
        if (state.operator === 'BETWEEN') {
            inputMinValueRef.current?.focus();
        } else {
            inputValueRef.current?.focus();
        }
    }, [state.operator, open]);

    const handleChangeValue = (paramName: keyof PriceVariationFilterProps, value: string | number) => {
        const valueFormatted = value.toString().replace(',', '.');
        onChangeValue(paramName, parseFloat(valueFormatted));
    };

    return (
        <ButtonGroup className={classNames('quick-action-filter__button__container quick-action-filter__toggle', styles.container)}>
            <IconButton icon={icon} placeholder={placeholder} skin={skinColor} onClick={onClick} />

            <Dropdown
                activeKey="1"
                eventKey="1"
                menuStyle={menuStyleState}
                placement="bottomEnd"
                onClose={() => {
                    setOpen(false);
                    setMenuStyleState(menuStyle);
                    onClose();
                }}
                onOpen={() => {
                    setOpen(true);
                    onOpen?.();
                }}
                renderTitle={() => <IconButton icon="MdKeyboardArrowDown" className="quick-action-filter__toggle__arrow" skin={skinColor} />}
                className={classNames(styles['dropdown-container'], styles['main-container'])}
            >
                <Dropdown.Item panel>
                    <p className={styles.title}>Filtrar preços alterados com variação</p>

                    <div className={styles['operator-container']}>
                        <Dropdown
                            onOpen={() => {
                                setMenuStyleState({ ...menuStyle, height: 220 });
                            }}
                            onClose={() => {
                                setMenuStyleState(menuStyle);
                            }}
                            className={styles['operator-content']}
                            title={SELECTOR.find((item) => item.value === state.operator)?.label}
                            value={state.operator}
                            renderTitle={(item) => {
                                return (
                                    <div className={styles['render-title']}>
                                        <p>{item}</p>
                                        <MdKeyboardArrowDown color="#959595" />
                                    </div>
                                );
                            }}
                        >
                            {SELECTOR.filter((item) => item.value !== state.operator)
                                .concat(SELECTOR.find((item) => item.value === state.operator) || { label: '', value: '' })
                                .map((item) => {
                                    return (
                                        <Dropdown.Item
                                            key={item.value}
                                            value={item.value}
                                            onSelect={() => {
                                                onChangeValue('operator', item.value);
                                            }}
                                        >
                                            {item.label}
                                        </Dropdown.Item>
                                    );
                                })
                                .reverse()}
                        </Dropdown>

                        {state.operator === 'BETWEEN' ? (
                            <>
                                <InputGroup style={{ maxWidth: 102 }}>
                                    <CustomNumberInput
                                        ref={inputMinValueRef}
                                        value={state.minValue}
                                        onChangeValue={(value: string) => handleChangeValue('minValue', value)}
                                        allowNegative={false}
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                                <span>e</span>
                                <InputGroup style={{ maxWidth: 102 }}>
                                    <CustomNumberInput
                                        ref={inputValueRef}
                                        value={state.maxValue}
                                        onChangeValue={(value: string) => handleChangeValue('maxValue', value)}
                                        allowNegative={false}
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </>
                        ) : (
                            <>
                                <span>a</span>
                                <InputGroup style={{ maxWidth: 102 }} className={styles['input-group']}>
                                    <CustomNumberInput
                                        ref={inputValueRef}
                                        value={state.value}
                                        onChangeValue={(value: string) => handleChangeValue('value', value)}
                                        allowNegative={false}
                                    />

                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </>
                        )}
                    </div>
                </Dropdown.Item>

                <Dropdown.Item panel>
                    <CheckboxGroup name="valueFilterType" value={state.valueFilterType} onChange={(value) => onChangeValue('valueFilterType', value)}>
                        {OPTIONS.map((item) => {
                            return (
                                <Checkbox
                                    key={item.id}
                                    value={item.value}
                                    checked={state.valueFilterType.includes(item.value as PriceVariationValueFilterType)}
                                >
                                    {item.label}
                                </Checkbox>
                            );
                        })}
                    </CheckboxGroup>
                </Dropdown.Item>
            </Dropdown>
        </ButtonGroup>
    );
};
