import React, { useEffect, useReducer, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Container, Icon, Toggle } from 'rsuite';

import { lead } from '../../../../services/EventLeadService';
import { getAccess, setRequestChangeStatus } from './services';

import { changeRequestStatus, sortNameConversion } from './data';

import lockIcon from '../../../../assets/icons/icon_lock.svg';
import { NotificationMessage } from '../../../../components';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../components/ModalConfirm';
import {
    reducerOnDemandSelector,
    resetOnDemandFilters,
    setOnDemandState,
    setSingleRequestData,
} from '../../../../reducers/reducerOnDemand';
import DataTableFilters from './Components/DataTableFilters';
import { ModalEditRequest } from './Components/ModalEditRequest';
import { ModalOnDemandPlan } from './Components/ModalOnDemandPlan';
import { PageHeader } from './Components/PageHeader';
import RequestDetailContainer from './Components/RequestDetailContainer/RequestDetailContainer';
import { ResumoArea } from './Components/ResumoArea';
import { TableOnDemand } from './Components/TableOnDemand';
import { RequestDetailsContent } from './Content/RequestDetailsContent';
import { RequestEditContent } from './Content/RequestEditContent';
import useOnDemandDownload from './Hooks/useOnDemandDownload';
import useOnDemandEditRequest from './Hooks/useOnDemandEditRequest';
import useOnDemandSummary from './Hooks/useOnDemandSummary';
import useOnDemandTableRequest from './Hooks/useOnDemandTableRequest';
import useSystemRequestSelectRegion from './Hooks/useSystemRequestSelectRegion';
import { initialState, onDemandReducer } from './Reducer/OnDemandReducer';

const MemoModalConfirm = React.memo(ModalConfirm);
const MemoModalEditRequest = React.memo(ModalEditRequest);
const MemoResumoArea = React.memo(ResumoArea);
const MemoTableOnDemand = React.memo(TableOnDemand);

const hasSevenDaysPermitionInList = (list) => {
    const permit = list.some((item) => item.edicaoBloqueada === true);
    return permit;
};

const OnDemand = () => {
    const [state, dispatch] = useReducer(onDemandReducer, initialState);
    const reduceDispatch = useDispatch();

    const [hasPermission, setHasPermission] = useState(null);

    const hasNoFilters =
        state?.filters &&
        Object.values(state?.filters).every((e) => e === null);

    const { clienteData } = useSelector(
        (state) => ({
            clienteData: state.clienteDataReducer?.clienteData,
        }),
        shallowEqual,
    );

    const {
        singleRequestData,
        canEditSave,
        tableRequestBody,
        checkedItems,
        selectedBulkList,
    } = useSelector(reducerOnDemandSelector);

    const { tableData, refetch } = useOnDemandTableRequest();

    const { summaryData, handleGetSummary } = useOnDemandSummary();

    const { ufData, cidadeData, handleGetUF, handleGetCidade } =
        useSystemRequestSelectRegion();

    const { editHandlers, editState, editCep } = useOnDemandEditRequest();
    const { handleDownload, tableIndividualDownload } = useOnDemandDownload();

    const hasNotSummary =
        summaryData?.available <= 0 ||
        summaryData?.available === null ||
        summaryData?.available === undefined;

    const handleChangePage = (page) => {
        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    page: page - 1,
                },
            }),
        );
        setTimeout(() => {
            refetch();
        }, 200);
    };

    const showResult = () => {
        refetch();
        setTimeout(() => {
            handleGetSummary();
        }, 300);
    };

    const resetSelectAllPages = () => {
        reduceDispatch(
            setOnDemandState({
                name: 'checkedItems',
                data: [],
            }),
        );

        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    selectAll: false,
                },
            }),
        );
    };

    const handleConfirmChangeStatus = async (item) => {
        const data = {
            ids: item.ids,
            status: item.status,
            selectAll: !!tableRequestBody.selectAll,
        };

        if (item.type === '') {
            const response = await setRequestChangeStatus(data);

            if (response.status === 200) {
                NotificationMessage(
                    'success',
                    5000,
                    'Status da solicitação alterado com sucesso',
                );
                resetSelectAllPages();
                showResult();
            }
        }
    };

    const sendCTA = () => {
        const identificador = 'upsell_infopanel';

        lead(identificador, clienteData).then(() => {
            dispatch({
                type: 'SET_SHOW_MODAL_PLAN',
                showModalPlan: false,
            });
            Alert.success('Em breve entraremos em contato!');
        });
    };

    const handleChangeCheckboxAll = (value, check) => {
        if (check) {
            const ids = tableData?.content.map((item) => item.idSolicitacao);
            reduceDispatch(
                setOnDemandState({
                    name: 'checkedItems',
                    data: ids,
                }),
            );
        } else {
            resetSelectAllPages();
        }
    };

    const handleAddRemoveOnDemandTableItem = (item) => {
        const hasValue = selectedBulkList.some(
            (i) => i.idSolicitacao === item.idSolicitacao,
        );
        if (!hasValue) {
            reduceDispatch(
                setOnDemandState({
                    name: 'selectedBulkList',
                    data: [...selectedBulkList, item],
                }),
            );
        } else {
            const removedItem = selectedBulkList.filter(
                (i) => i.idSolicitacao !== item.idSolicitacao,
            );
            reduceDispatch(
                setOnDemandState({
                    name: 'selectedBulkList',
                    data: removedItem,
                }),
            );
        }
    };

    const handleChangeCheckbox = (value, checked, item) => {
        const ids = checked
            ? [...checkedItems, value]
            : checkedItems.filter((item) => item !== value);

        reduceDispatch(
            setOnDemandState({
                name: 'checkedItems',
                data: ids,
            }),
        );
        handleAddRemoveOnDemandTableItem(item);

        if (!checked) {
            reduceDispatch(
                setOnDemandState({
                    name: 'tableRequestBody',
                    data: {
                        ...tableRequestBody,
                        selectAll: false,
                    },
                }),
            );
        }
    };

    const handleTableIndividualItem = (value, id) => {
        const type = {
            1: 'edit',
            2: 'CSV',
            3: 'XLSX',
        };

        if (value === 1) {
            const requestData = tableData.content.filter(
                (item) => item.idSolicitacao === id,
            );
            editState.setOpenEditModal(true);
            reduceDispatch(
                setSingleRequestData({
                    name: 'singleRequestData',
                    data: requestData[0],
                }),
            );

            setTimeout(() => {
                handleGetUF(requestData[0]?.cidade);
            }, 300);
            handleGetCidade(requestData[0]?.uf);
        } else {
            tableIndividualDownload(type[value], id);
        }
    };

    const handleSelectWholeList = () => {
        const ids = tableData?.content.map((item) => item.idSolicitacao);

        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    selectAll: true,
                },
            }),
        );
        reduceDispatch(
            setOnDemandState({
                name: 'checkedItems',
                data: ids,
            }),
        );
    };

    const handleSortTableColumn = (column, type) => {
        const orderType = {
            asc: 'ASC',
            desc: 'DESC',
        };
        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    order: { [sortNameConversion[column]]: orderType[type] },
                },
            }),
        );
        setTimeout(() => {
            refetch();
        }, 100);
    };

    const handleShowChangeStatusModal = (item, status) => {
        if (item.idSolicitacao && hasNotSummary && status === true) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'notAvailable',
            });
        }

        if (
            item.idSolicitacao &&
            summaryData?.available > 0 &&
            status === true
        ) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'statusTrue',
            });

            dispatch({
                type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                openStatusChangeModal: true,
            });
        }

        if (item.idSolicitacao && status === false) {
            if (item.edicaoBloqueada === true) {
                dispatch({
                    type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                    statusChangeModalType: 'noEdit',
                });
                dispatch({
                    type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                    openStatusChangeModal: true,
                });
                dispatch({
                    type: 'SET_OPEN_BULK_SELECTION_MODAL',
                    openBulkSelectionModal: false,
                });
            } else {
                dispatch({
                    type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                    statusChangeModalType: 'statusFalse',
                });
                dispatch({
                    type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                    openStatusChangeModal: true,
                });
            }
        }

        dispatch({
            type: 'SET_ITEM_DATA',
            itemData: {
                ...state.itemData,
                ids: [item.idSolicitacao],
                status,
                type:
                    hasNotSummary && state.toggleIsActive === true
                        ? 'notAvailable'
                        : '',
            },
        });
    };

    const handleBulkSelectionSave = () => {
        if (
            selectedBulkList.length &&
            hasSevenDaysPermitionInList(selectedBulkList) &&
            state.toggleIsActive === false
        ) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'noEdit',
            });
            dispatch({
                type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                openStatusChangeModal: true,
            });
            dispatch({
                type: 'SET_OPEN_BULK_SELECTION_MODAL',
                openBulkSelectionModal: false,
            });
        } else if (
            (hasNotSummary || checkedItems.length > summaryData?.available) &&
            state.toggleIsActive === true
        ) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'notAvailable',
            });
            dispatch({
                type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                openStatusChangeModal: true,
            });
            dispatch({
                type: 'SET_OPEN_BULK_SELECTION_MODAL',
                openBulkSelectionModal: false,
            });
        } else {
            handleConfirmChangeStatus(state.itemData);
            dispatch({
                type: 'SET_OPEN_BULK_SELECTION_MODAL',
                openBulkSelectionModal: false,
            });
            reduceDispatch(
                setOnDemandState({
                    name: 'checkedItems',
                    data: [],
                }),
            );
        }
    };

    const handleOnConfirmBulkStatusChangeModal = () => {
        handleConfirmChangeStatus(state.itemData);
        dispatch({
            type: 'SET_OPEN_STATUS_CHANGE_MODAL',
            openStatusChangeModal: false,
        });
        state.statusChangeModalType === 'notAvailable' &&
            window.open('https://infoprice-support.freshdesk.com/support/home');
    };

    const getPermition = async () => {
        const access = await getAccess();
        setHasPermission(access);
    };

    useEffect(() => {
        setTimeout(() => {
            if (hasPermission?.available) {
                handleGetSummary();
            }
        }, 300);
    }, [hasPermission]);

    useEffect(() => {
        getPermition();

        return () => {
            reduceDispatch(resetOnDemandFilters());
        };
    }, []);

    useEffect(() => {
        if (checkedItems.length) {
            dispatch({
                type: 'SET_ITEM_DATA',
                itemData: {
                    ...state.itemData,
                    ids: checkedItems,
                    status: state.toggleIsActive,
                    type:
                        hasNotSummary && state.toggleIsActive === true
                            ? 'notAvailable'
                            : '',
                },
            });
        }
    }, [checkedItems, state.toggleIsActive, hasNotSummary]);

    return (
        <Container className="main-container on-demand-page">
            <LoadingSpiner size="md" />
            {!hasPermission?.available ? (
                <div className="block-plan">
                    <div className="block-plan-icon-circle">
                        <img src={lockIcon} alt="" />
                    </div>
                    <p>
                        No momento, essa modalidade não está disponível para a
                        sua região contratada.
                    </p>
                </div>
            ) : null}
            <PageHeader showResultList={showResult} />
            <MemoResumoArea
                requestSummaryData={summaryData}
                onClickShowModalPlan={() =>
                    dispatch({
                        type: 'SET_SHOW_MODAL_PLAN',
                        showModalPlan: true,
                    })
                }
            />
            <RequestDetailContainer
                requests={tableData}
                onChangePage={handleChangePage}
                download={(extension) =>
                    handleDownload(extension, tableData, checkedItems)
                }
                summaryData={summaryData}
                onConfirmStatusChange={handleConfirmChangeStatus}
                onClickSelectWholeList={handleSelectWholeList}
                onClickUpdateStatus={() =>
                    dispatch({
                        type: 'SET_OPEN_BULK_SELECTION_MODAL',
                        openBulkSelectionModal: true,
                    })
                }
                checkedItems={checkedItems}
                filterArea={<DataTableFilters />}
                tableArea={
                    <MemoTableOnDemand
                        requests={tableData}
                        summaryData={summaryData}
                        onChangePage={handleChangePage}
                        onChangeCheckAll={handleChangeCheckboxAll}
                        onChangeCheck={handleChangeCheckbox}
                        onSelectTableIndividualItem={handleTableIndividualItem}
                        onSortColumn={handleSortTableColumn}
                        checkedItems={checkedItems}
                        onChangeIndividualStatus={handleShowChangeStatusModal}
                        onClickSelectWholeList={handleSelectWholeList}
                    />
                }
            />
            <ModalOnDemandPlan
                show={state?.showModalPlan}
                requestSummaryData={summaryData}
                onCancel={() =>
                    dispatch({
                        type: 'SET_SHOW_MODAL_PLAN',
                        showModalPlan: false,
                    })
                }
                onConfirm={sendCTA}
            />
            <ModalConfirm
                show={state.openStatusChangeModal}
                modalWidth={
                    changeRequestStatus[state.statusChangeModalType]?.modalWidth
                }
                titleFontSize="20px"
                textAlign="center"
                footerAlign="center"
                cancelButtonWidth={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.cancelBtnWidth
                }
                confirmButtonWidth={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.confirmBtnWidth
                }
                icon={changeRequestStatus[state.statusChangeModalType]?.icon}
                title={changeRequestStatus[state.statusChangeModalType]?.title}
                message={
                    changeRequestStatus[state.statusChangeModalType]?.message
                }
                confirmButton={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.confirmButton
                }
                cancelButton={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.cancelButton
                }
                onCancel={() => {
                    dispatch({
                        type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                        openStatusChangeModal: false,
                    });
                }}
                onConfirm={() => handleOnConfirmBulkStatusChangeModal()}
                onHide={() => {
                    dispatch({
                        type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                        openStatusChangeModal: false,
                    });
                }}
            />
            <MemoModalConfirm
                show={state.openBulkSelectionModal}
                className="modal-confirm-bulk-selection"
                title="Alterar status das solicitações"
                message="Todas as solicitações selecionadas serão alteradas para:"
                confirmButton="SALVAR"
                cancelButton="CANCELAR"
                footerAlign="center"
                textAlign="center"
                confirmButtonWidth="183px"
                cancelButtonWidth="183px"
                size="xs"
                titleFontSize="20px"
                modalWidth="430px"
                onCancel={() =>
                    dispatch({
                        type: 'SET_OPEN_BULK_SELECTION_MODAL',
                        openBulkSelectionModal: false,
                    })
                }
                onConfirm={handleBulkSelectionSave}
            >
                <div className="modal-confirm-bulk-selection__body">
                    <span
                        style={{
                            fontWeight: !state.toggleIsActive
                                ? 'bold'
                                : 'normal',
                        }}
                    >
                        Inativas
                    </span>
                    <Toggle
                        checkedChildren={
                            <Icon
                                icon="check"
                                style={{
                                    fontSize: '10px',
                                    verticalAlign: 'unset',
                                }}
                            />
                        }
                        unCheckedChildren={
                            <Icon
                                icon="close"
                                style={{
                                    fontSize: '10px',
                                    verticalAlign: 'unset',
                                }}
                            />
                        }
                        checked={state.toggleIsActive}
                        onChange={(value) =>
                            dispatch({
                                type: 'SET_TOGGLE_IS_ACTIVE',
                                toggleIsActive: value,
                            })
                        }
                    />
                    <span
                        style={{
                            fontWeight: state.toggleIsActive
                                ? 'bold'
                                : 'normal',
                        }}
                    >
                        Ativas
                    </span>
                </div>
            </MemoModalConfirm>
            <MemoModalEditRequest
                show={editState.openEditModal}
                onConfirm={editHandlers.handleEditConfirm}
                onCancel={editHandlers.handleOnCancelEditModal}
                contentType={editState.modalEditContent}
                canSave={canEditSave}
                canEdit={singleRequestData.edicaoBloqueada}
                content1={
                    <RequestEditContent
                        dataUF={ufData}
                        dataCidade={cidadeData}
                        dataValue={singleRequestData}
                        errorCEP={editCep.isError}
                        cepLoading={editCep.isFetching}
                        onOpenUF={handleGetUF}
                        onOpenCidade={handleGetCidade}
                        onSearchCidade={handleGetCidade}
                        onSelectRegion={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeCep={editHandlers.handleOnChangeCep}
                        onChangeGTIN={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeKeyword={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeLogradouro={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeNumeroLogradouro={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeRaio={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeRadioProduct={
                            editHandlers.handleChangeProductType
                        }
                    />
                }
                content2={<RequestDetailsContent data={singleRequestData} />}
            />
        </Container>
    );
};

export default withRouter(OnDemand);
