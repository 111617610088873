import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { Sort } from '../../../@types/sort';
import { SET_GERENCIADOR_CONTEXT_MENU } from '../../../reducers/gerenciadorPrecos/contextMenu';
import { selectorDatapoints, SET_GERENCIADOR_DATAPOINTS, UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX } from '../../../reducers/gerenciadorPrecos/datapoints';
import { UPDATE_DATAPOINT_CACHE_BY_ID } from '../../../reducers/gerenciadorPrecos/datapointsCache';

import { RootState } from '../../../@types/RootState';
import { AlertWithTimestamp } from '../../../components/AlertWithTimestamp';
import ExceptionSaveDatapoint from '../../../data/ExceptionSaveDatapoint';
import { selectorExpandedRowKey } from '../../../reducers/gerenciadorPrecos/expandedRowKey';
import { useSelectedDatapoints } from './hooks';
import useGerenciadorSort from './hooks/useGerenciadorSort';
import { saveEditedDatapoint } from './services';
import { ExpandedRowHeight } from './utils';
import * as calcHandlers from './utils/utils';

type RowData = GerenciadorPrecos.RowData;

const ROW_KEY = 'productsToBePricedId';

export type UseGerenciadorTableProps = {
    setUpdateBigNumbers: (value: boolean) => void;
};

const useGerenciadorTable = ({ setUpdateBigNumbers }: UseGerenciadorTableProps) => {
    const datapointExhibitionType = useSelector((state: RootState) => {
        return state.datapointExhibitionType.datapointExhibitionType;
    });

    const dispatch = useDispatch();

    const datapoints = useSelector(selectorDatapoints);

    const { sort, datakeys, onSortColumn, onUpdateSortColumn, onUpdateSortOrder } = useGerenciadorSort();

    const expandedRowKey = useSelector(selectorExpandedRowKey);

    const expandedRowOptions = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.expandedRowKey.options;
    });

    const [dataKeyProduto, setDataKeyProduto] = React.useState('productId');

    const selectedDatapoints = useSelectedDatapoints();

    const { updateList, handleCheckAll, isToggleChecked } = selectedDatapoints;

    const handleContextMenu = React.useCallback(
        (rowData: RowData) => {
            dispatch(SET_GERENCIADOR_CONTEXT_MENU(rowData));
        },
        [dispatch],
    );
    const handleUpdateCampo = React.useCallback(
        (name: GerenciadorPrecos.Inputs, value: number, rowData: RowData, index: number) => {
            let data: Partial<RowData> = {
                priceType: 'EDITADO' as 'EDITED',
            };

            if (value > 0) {
                data = { ...data, novoCalculo: true };
                switch (name) {
                    case 'novoPreco':
                        data = {
                            ...data,
                            ...calcHandlers.handleCalculoPreco(value, rowData),
                        };
                        break;
                    case 'novaCompetitividade':
                        data = {
                            ...data,
                            ...calcHandlers.handleCalculoCompetitividade(value, rowData),
                        };
                        break;
                    case 'novaMargem':
                        data = {
                            ...data,
                            ...calcHandlers.handleCalculoMargem(value, rowData),
                        };
                        break;
                    default:
                        break;
                }
            } else {
                data = {
                    price: value,
                    novaMargem: 0,
                    novaCompetitividade: 0,
                };
            }

            dispatch(UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX({ index, data }));
        },
        [dispatch],
    );

    const handleBlurSuccess = useCallback(
        (editedDatapoints: Partial<RowData>[]) => {
            if (!editedDatapoints.length) return;

            const updatedDatapoints = [...datapoints];

            let shouldDispatch = false;

            editedDatapoints.forEach(
                ({
                    productsToBePricedId = '',
                    price = null,
                    priceType = null,
                    newMargin: novaMargem = null,
                    newCompetitivenessPrice: novaCompetitividade = null,
                    wholesale = null,
                    offer = null,
                    shouldUpdateProduct = false,
                }) => {
                    const index = updatedDatapoints.findIndex((datapoint) => {
                        return datapoint.productsToBePricedId === productsToBePricedId;
                    });

                    if (index === -1 || !updatedDatapoints[index]) return;

                    const rowData = { ...updatedDatapoints[index] };

                    if (shouldUpdateProduct) {
                        shouldDispatch = true;
                        rowData.price = price;
                        rowData.priceType = priceType;
                        rowData.novaMargem = novaMargem;
                        rowData.novaCompetitividade = novaCompetitividade;
                    }

                    if (rowData.wholesale && wholesale) {
                        shouldDispatch = true;
                        const updatedWholesale = { ...rowData.wholesale };
                        updatedWholesale.price = wholesale.price;
                        updatedWholesale.newMargin = wholesale.newMargin;
                        updatedWholesale.newCompetitivenessPrice = wholesale.newCompetitivenessPrice;
                        updatedWholesale.discountPercentage = wholesale.discountPercentage;
                        rowData.wholesale = updatedWholesale;
                    }

                    if (rowData.offer && offer) {
                        shouldDispatch = true;
                        const updatedOffer = { ...rowData.offer };
                        updatedOffer.price = offer.price;
                        updatedOffer.newMargin = offer.newMargin;
                        updatedOffer.newCompetitivenessPrice = offer.newCompetitivenessPrice ?? null;
                        updatedOffer.discountPercentage = offer.discountPercentage ?? null;
                        rowData.offer = updatedOffer;
                    }

                    updatedDatapoints[index] = rowData;
                },
            );

            if (shouldDispatch && datapointExhibitionType !== 'FAMILY') {
                dispatch(SET_GERENCIADOR_DATAPOINTS(updatedDatapoints));
            }
        },
        [datapoints, datapointExhibitionType, dispatch],
    );

    const handleBlur = React.useCallback(
        async (rowData: RowData) => {
            const { novoCalculo, storeId, productsToBePricedId, productId, price, storeName } = rowData;

            if (!novoCalculo) return;

            const isSelected = isToggleChecked(productsToBePricedId);

            if (isSelected) {
                dispatch(
                    UPDATE_DATAPOINT_CACHE_BY_ID({
                        productsToBePricedId,
                        updateList,
                        rowData,
                    }),
                );
            }

            try {
                const { data = [], status } = await saveEditedDatapoint({
                    productId,
                    storeId,
                    price,
                    productsToBePricedId,
                    datapointExhibitionType,
                });

                if (status !== 200) return;

                setUpdateBigNumbers(true);

                handleBlurSuccess(data);
            } catch {
                const datapoint = { productId, storeId, storeName };
                const { message } = new ExceptionSaveDatapoint(datapoint);
                AlertWithTimestamp({ message });
            }
        },
        [isToggleChecked, dispatch, updateList, datapointExhibitionType, setUpdateBigNumbers, handleBlurSuccess],
    );

    const sortModel: Sort = React.useMemo(
        () => ({
            orderBy: sort.orderBy,
            type: sort.type,
        }),
        [sort],
    );

    const onChangeCheckAll = React.useCallback((checked: boolean) => handleCheckAll(checked, datapoints), [handleCheckAll, datapoints]);

    const rowExpandedHeight = useMemo(() => ExpandedRowHeight.calculateExpandedRowHeight(expandedRowOptions), [expandedRowOptions]);

    return {
        data: datapoints,
        datakeys,
        rowKey: ROW_KEY,
        sortColumn: sort.type,
        sortType: sort.orderBy,
        headerHeight: 46,
        rowHeight: 62,
        minHeight: 600,
        rowExpandedHeight,
        dataKeyProduto,
        sort: sortModel,
        expandedRowKeys: [expandedRowKey.key] as string[],
        selectedDatapoints,
        onSortColumn,
        onUpdateSortColumn,
        handleContextMenu,
        handleBlur,
        handleUpdateCampo,
        onChangeCheckAll,
        setDataKeyProduto,
        onUpdateSortOrder,
    } as const;
};

export default useGerenciadorTable;
