import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL

// ########## - CRIAR TABELA COMERCIAL - ##########

export const getBaseProductList = (data) => trackPromise(
    api({
        url: `${URL}/produto-base/by-uf/NACIONAL`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
        },
    }).then((res) => res),
    'table-edit-base'
);

export const getUFList = () => trackPromise(
    api({
        url: `${URL}/metadados/ufs`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const getCanaisList = () => trackPromise(
    api({
        url: `${URL}/metadados/canais`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const getSubCanaisList = () => trackPromise(
    api({
        url: `${URL}/metadados/subcanais`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const getTiposRegraList = () => trackPromise(
    api({
        url: `${URL}/metadados/tipos-regra`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const getTiposLimiteList = () => trackPromise(
    api({
        url: `${URL}/metadados/tipos-limite`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const createSaveTabelaComercial = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'POST',
        expectToken: true,
        data,
    }).then((res) => res),
    'save-tabela-comercial'
);

export const saveEditTabelaComercial = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
    'save-tabela-comercial'
);

export const getTabelaBase = () => trackPromise(
    api({
        url: `${URL}/tabela-base`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);
