import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Table } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import {
    CompetitorPriceCell,
    HoverNomeColuna,
    MargemCell,
    PMZCell,
    PrecoSugeridoCell,
    PrecoVigenteCell,
} from '../../..';
import { GerenciadorPrecos } from '../../../../../../../@types/GerenciadorPrecos';
import { ButtonPrimary, InfoTable, Layout } from '../../../../../../../components';
import {
    ProductCell
} from '../../../../../../../components/TableCells';
import FatorCell from '../../../../../../../components/TableCells/FatorCell/FatorCell';
import { SET_GET_FAMILY_MODAL_IS_OPEN } from '../../../../../../../reducers/gerenciadorPrecos';
import { RowData } from '../../../../../../../reducers/gerenciadorPrecos/datapoints';
import {
    SET_GET_FAMILY_MODAL_DATAPOINTS,
    UPDATE_GET_FAMILY_MODAL_DATAPOINT,
    selectorGetFamilyModalDatapoints
} from '../../../../../../../reducers/getFamilyModal/datapoints';
import { CLEAR_EDITED_DATAPOINTS, UPDATE_EDITED_DATAPOINT, selectorGetEditedDatapoints } from '../../../../../../../reducers/getFamilyModal/editedDatapoints';
import {
    SET_GET_FAMILY_MODAL_ACTIVE_PAGE,
    SET_GET_FAMILY_MODAL_DISPLAY_LENGTH,
    selectorGetFamilyModalPagination,
} from '../../../../../../../reducers/getFamilyModal/pagination';

import { AlertWithTimestamp } from '../../../../../../../components/AlertWithTimestamp';
import { Exception } from '../../../../../../../data';
import { SET_GET_FAMILY_MODAL_SORT, selectorGetFamilyModalSort } from '../../../../../../../reducers/getFamilyModal/sort';
import { SET_GET_FAMILY_MODAL_TOTAL_ELEMENTS, selectorGetFamilyModalTotalElements } from '../../../../../../../reducers/getFamilyModal/totalElements';
import { checkIfIsLoading } from '../../../../../../../utils';
import { handleMoveFocusToNextRowInput } from '../../../../utils';
import * as calcHandlers from '../../../../utils/utils';
import { CpiCell } from '../../../TableCells/CpiCell';
import { GET_ITEMS_FAMILY_MODAL, UPDATE_PRODUCT_PRICES } from '../../service';
import { ContentFamilyModal, GET_ITEMS_FAMILY_MODAL_RESPONSE, ProductDataDTO, UpdateProductDataDTO } from '../../types';
import { GET_FAMILY_DATAPOINTS_MODEL } from '../../utils';
import styles from './GetFamilyTable.module.scss';

const { Column, HeaderCell, Cell } = Table;

const DATA_KEY = 'itemsFamily';

const MemoProductCell = memo(ProductCell);
const MemoFatorCell = memo(FatorCell);
const MemoCpiCell = memo(CpiCell);
const MemoHoverNomeColuna = memo(HoverNomeColuna);

const GetFamilyTable = ({ productFamilyId, onHide, storeId }: { productFamilyId: string, onHide: () => void, storeId: string }) => {
    const queryClient = useQueryClient();
    const editedDatapoints = useSelector(selectorGetEditedDatapoints);
    const dispatch = useDispatch();
    const pagination = useSelector(selectorGetFamilyModalPagination);
    const totalElements = useSelector(selectorGetFamilyModalTotalElements);
    const sort = useSelector(selectorGetFamilyModalSort);
    const datapoints = useSelector(selectorGetFamilyModalDatapoints);


    const handleSortColumn = useCallback(
        (sortColumn: string, sortType: TableProps['sortType']) => {
            dispatch(
                SET_GET_FAMILY_MODAL_SORT({
                    type: sortColumn,
                    orderBy: sortType,
                }),
            );
        },
        [],
    );


    const closeGetFamilyModal = () => {
        onHide();
        dispatch(SET_GET_FAMILY_MODAL_IS_OPEN(false));
        dispatch(CLEAR_EDITED_DATAPOINTS());
    };

    const GET_DATAPOINTS_MODEL = useMemo(() => {
        return GET_FAMILY_DATAPOINTS_MODEL({
            sort,
            pagination,
            productFamilyIds: productFamilyId,
            storeIds: storeId,
        });
    }, [sort, pagination, productFamilyId, storeId]);

    const handleGetDatapoints = useCallback(async () => {
        try {
            const res = await GET_ITEMS_FAMILY_MODAL(GET_DATAPOINTS_MODEL);
            return res;
        } catch {
            throw new Error('Erro ao buscar produtos');
        }
    }, [GET_DATAPOINTS_MODEL]);

    const handleSuccess = useCallback(
        (data: GET_ITEMS_FAMILY_MODAL_RESPONSE) => {
            const updatedContent = data.content.map((item) => {
                const editedItem = editedDatapoints[item.productsToBePricedId];
                if (editedItem) {
                    return {
                        ...item,
                        ...editedItem,
                    };
                }
                return item;
            });

            dispatch(SET_GET_FAMILY_MODAL_DATAPOINTS(updatedContent));
            dispatch(SET_GET_FAMILY_MODAL_TOTAL_ELEMENTS(data.totalElements));
        },
        [dispatch, editedDatapoints]
    );


    const handleChangePage = useCallback(
        (page: number) => {
            dispatch(
                SET_GET_FAMILY_MODAL_ACTIVE_PAGE(page - 1),
            );
        },
        [dispatch],
    );

    const handleChangeLength = useCallback(
        (size: number) => {
            const lastValidIndex = Math.ceil(totalElements / size);

            const isOnInvalidLastIndex =
                pagination.activePage + 1 > lastValidIndex;

            if (isOnInvalidLastIndex) handleChangePage(lastValidIndex);

            dispatch(
                SET_GET_FAMILY_MODAL_DISPLAY_LENGTH(size),
            );
        },
        [dispatch, totalElements, pagination.activePage, handleChangePage],
    );

    const memoPagination = useMemo(() => {
        return {
            total: totalElements,
            activePage: pagination.activePage + 1,
            displayLength: pagination.displayLength,
            lengthMenu: [
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
            ],
            onChangePage: handleChangePage,
            onChangeLength: handleChangeLength,
        };
    }, [totalElements, pagination, handleChangePage, handleChangeLength]);

    const { fetchStatus } = useQuery({
        queryKey: ['ipa/gerenciador/familias', GET_DATAPOINTS_MODEL],
        queryFn: handleGetDatapoints,
        onSuccess: handleSuccess,
        retry: false,
    });

    const isLoading = useMemo(() => checkIfIsLoading(fetchStatus), [fetchStatus]);

    const handleUpdateCampo = useCallback(
        (
            name: GerenciadorPrecos.Inputs,
            value: number,
            rowData: RowData,
            index: number,
        ) => {
            let updatedData: Partial<RowData> = {
                priceType: 'EDITADO' as 'EDITED',
            };

            if (value > 0) {
                updatedData = { ...updatedData, novoCalculo: true };
                switch (name) {
                    case 'novoPreco':
                        updatedData = {
                            ...updatedData,
                            ...calcHandlers.handleCalculoPreco(value, rowData),
                        };
                        break;
                    case 'novaCompetitividade':
                        updatedData = {
                            ...updatedData,
                            ...calcHandlers.handleCalculoCompetitividade(value, rowData),
                        };
                        break;
                    case 'novaMargem':
                        updatedData = {
                            ...updatedData,
                            ...calcHandlers.handleCalculoMargem(value, rowData),
                        };
                        break;
                    default:
                        break;
                }
            } else {
                updatedData = {
                    price: value,
                    novaMargem: 0,
                    novaCompetitividade: 0,
                };
            }

            dispatch(
                UPDATE_EDITED_DATAPOINT({
                    key: rowData.productsToBePricedId,
                    updatedData: {
                        ...rowData,
                        ...updatedData,
                    },
                }),
            );

            dispatch(
                UPDATE_GET_FAMILY_MODAL_DATAPOINT({
                    index,
                    updatedData,
                }),
            );

        },
        [dispatch]
    );


    const formattedData = useMemo((): UpdateProductDataDTO => {
        const productDataDTOList: ProductDataDTO[] = Object.keys(editedDatapoints).map((productsToBePricedId) => {
            const editedItem = editedDatapoints[productsToBePricedId]
            return {
                productsToBePricedId,
                editedPrice: editedItem.price ?? 0,
            }
        });
        return {
            productDataDTOList
        }
    },
        [editedDatapoints]);

    const { mutate } = useMutation(UPDATE_PRODUCT_PRICES, {
        onSuccess: () => {
            Alert.success('Alterações realizadas com sucesso!');
            queryClient.invalidateQueries(['ipa.gerenciador.datapoints']);
        },
        onError: () => {
            const { message } = new Exception('Erro ao atualizar preços!');
            AlertWithTimestamp({ message });
        },
    });

    const handleSaveChanges = () => {
        if (Object.keys(editedDatapoints).length === 0) {
            closeGetFamilyModal()
        } else {
            mutate(formattedData);
            dispatch(CLEAR_EDITED_DATAPOINTS());
            dispatch(SET_GET_FAMILY_MODAL_IS_OPEN(false));
        }
    };

    useEffect(() => {
        const tableContainer = document.getElementById('family-table');
        if (tableContainer) {
            tableContainer.addEventListener('keydown', (e) => {
                handleMoveFocusToNextRowInput(e) as unknown as HTMLInputElement;
            });
        }
        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('keydown', (e) => {
                    handleMoveFocusToNextRowInput(e) as unknown as HTMLInputElement;
                });
            }
        };
    }, []);



    return (
        <Layout.Section padded className={styles['layout-section']}>
            <InfoTable
                id="family-table"
                dataKey={DATA_KEY}
                data={datapoints}
                className={styles['gerenciador-page__table']}
                loading={isLoading}
                sortColumn={sort.type}
                sortType={sort.orderBy as TableProps['sortType']}
                headerHeight={46}
                rowHeight={62}
                onSortColumn={handleSortColumn}
                pagination={memoPagination}
                style={{ overflow: datapoints.length > 7 ? "auto" : "hidden", height: '500px' }}
                >
                {/* @ts-ignore */}

                <Column sortable flexGrow={1}>
                    <HeaderCell>Produto</HeaderCell>
                    <Cell dataKey="productIds">
                        {(rowData: ContentFamilyModal) => (
                            <MemoProductCell rowData={rowData} />
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={100}>
                    <HeaderCell>Fator</HeaderCell>
                    <Cell dataKey="factor">
                        {(rowData: ContentFamilyModal) => <MemoFatorCell rowData={rowData} />}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable minWidth={78}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <div>
                            <p>PMZ e</p>
                            <p>custo</p>
                        </div>
                    </HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData: RowData) => <PMZCell rowData={rowData} />}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={124}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <div>
                            <p>Preço</p>
                            <p>vigente</p>
                        </div>
                    </HeaderCell>
                    <Cell dataKey="priceRetail">
                        {(rowData: RowData) => <PrecoVigenteCell rowData={rowData} />}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={127}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <div>
                            <p>Preço</p>
                            <p>concorrente</p>
                        </div>
                    </HeaderCell>
                    <Cell dataKey="competitorsPrice">
                        {(rowData: RowData) => <CompetitorPriceCell rowData={rowData} />}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={132}>
                    <HeaderCell>Preço sugerido</HeaderCell>
                    <Cell className={styles['table__input-group']} dataKey="price">
                        {(rowData: RowData, index: number) => (
                            <PrecoSugeridoCell
                                className={classNames(styles['table__input-group'], styles['gerenciador-page__table__input-group'])}
                                dataKey="novoPreco"
                                rowData={rowData}
                                index={index}
                                handleUpdateCampo={handleUpdateCampo}
                                handleBlur={() => console.log('blur')}
                            />
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={116}>
                    <HeaderCell>
                        <MemoHoverNomeColuna placement="bottom" text="CPI" tooltip="Competitividade" />
                    </HeaderCell>
                    <Cell dataKey="cpi">
                        {(rowData: RowData, index: number) => (
                            <MemoCpiCell
                                dataKey="novaCompetitividade"
                                rowData={rowData}
                                index={index}
                                handleUpdateCampo={handleUpdateCampo}
                                handleBlur={() => console.log('blur')}
                            />
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={116}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>Margem</HeaderCell>
                    <Cell className={styles['table__input-group']} dataKey="newMargin">
                        {(rowData: RowData, index: number) => (
                            <MargemCell
                                dataKey="novaMargem"
                                rowData={rowData}
                                index={index}
                                handleUpdateCampo={handleUpdateCampo}
                                handleBlur={() => console.log('blur')}
                            />
                        )}
                    </Cell>
                </Column>
            </InfoTable>
            <div className={styles.footer}>
                <ButtonPrimary onClick={closeGetFamilyModal} type="reset" theme="ghost">
                    FECHAR
                </ButtonPrimary>
                <ButtonPrimary onClick={handleSaveChanges} type="submit">SALVAR ALTERAÇÕES</ButtonPrimary>
            </div>
        </Layout.Section>
    );
};

export default GetFamilyTable;
