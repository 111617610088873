import React, { useState } from 'react'
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
    InputGroup,
    Input,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Indicator } from '../../../../components';
import { ContextMenu, ContextMenuTrigger, InputCurrency, LinkButton, LoadingSpinerArea } from '../../../../../../../components';
import { useManageComercialTableStore } from '../../ManageComercialTable.store';
import { NewPriceTableInputContextMenu } from './NewPriceTableInputContextMenu';

import styles from './ManageComercialTableTable.module.scss'
import {
    comercialMargemBrutaCalc,
    variacaoMargemBruta,
    comercialMargemOperacionalCalc,
    comercialPrecoMedioProjetadoCalc,
    PMZCalc,
    precoMedioProjetadoCalc,
    tabelaAtualComercialPercentage,
    tabelaAtualPercentage,
    tabelaBaseComercialPercentage,
    variacaoMargemOperacionalCalc,
    PMZComercialCalc,
    outrosCustosComercialCalc,
    precoEditadoEPrecoTabelaAtualDiffCalc,
    comercialPrecoEditadoEPrecoTabelaAtualDiffCalc,
    statusPriceLimit,
    precoAutoComercialCalc
} from '../../../../calculos';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

type ManageComercialTableTableProps = {
    data?: any,
    onClickPromo?: (rowData: any) => void,
    onChangeCheckbox?: (rowData: any, checked: boolean) => void,
    onBlurNovoPrecoTabela?: (event: React.FocusEvent<HTMLInputElement>, rowData: any) => void,
    onChangePage?: (value: number) => void,
    onChangeLength?: (value: number) => void,
}

export const ManageComercialTableTable = ({
    data,
    onBlurNovoPrecoTabela,
    onClickPromo,
    onChangeCheckbox,
    onChangePage,
    onChangeLength
}: ManageComercialTableTableProps) => {
    const [rowId, setRowId] = useState([])
    const {
        priceAnalysisComercialTableData,
        productItemData,
        comercialTablePriceLimitAndRules,
        comercialProductItemSelectedList,
        setOpenModalPriceAnalysisComercial,
        setManageComercialTableState,
        setOpenModalProductCostComercialTable,
    } = useManageComercialTableStore()

    const handleOnClickDetails = (rowData) => {
        setOpenModalPriceAnalysisComercial(true, rowData)
    }

    const handleOnClickPromo = (rowData) => {
        const hasId = rowId.some((item) => item === rowData.id)
        const addId = !hasId ? [...rowId, rowData.id] : rowId.filter((item) => item !== rowData.id)
        setRowId(addId)
        setManageComercialTableState('openModalActivateProductOffer', true)
        // onClickPromo(rowData)
    }

    const handleProductCost = (rowData) => {
        setOpenModalProductCostComercialTable(true, rowData)
    }

    const handleOpenModalPriceLimit = (data) => {
        setManageComercialTableState('openModalPriceLimit', true)
        setManageComercialTableState('productItemData', data)
    }

    return (
        <div className={styles['table-scenario-container']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={data?.content}
                autoHeight
                id="gerenciar-tabela-comercial"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox
                                checked={
                                    comercialProductItemSelectedList.some((item) =>
                                        item.produto.codigoInternoProduto === rowData?.produto.codigoInternoProduto)
                                }
                                value={rowData?.produto.codigoInternoProduto}
                                onChange={(v, checked) => onChangeCheckbox?.(rowData, checked)}
                            />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <div>
                                <div className={styles['product-code-wrapper']}>
                                    <span className={styles['main-bold-text']}>{rowData.produto.codigoInternoProduto}</span>
                                    <div className={styles.lider}>Líder</div>
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={<Tooltip>Produto com alteração pendente de revisão</Tooltip>}
                                    >
                                        <div className={styles['alert-icon-wrapper']}>
                                            <ReportProblemIcon className={styles['alert-icon']} />
                                        </div>
                                    </Whisper>
                                </div>
                                <Whisper
                                    delayShow={0.2}
                                    trigger="hover"
                                    placement="bottom"
                                    speaker={<Tooltip>{rowData.produto.descricaoProduto}</Tooltip>}
                                >
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto.descricaoProduto}
                                    </p>
                                </Whisper>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Família
                    </HeaderCell>
                    <Cell dataKey="linha" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['regular-text-family']}>{rowData.produto.categoriaNivel1}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>UF</HeaderCell>
                    <Cell dataKey="uf">
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['regular-text']}>
                                    {rowData.produtoComercial.uf}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custos</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="custoFabricacao"
                        className={styles.line}
                    >
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.produtoComercial.parametros.custoFabricacao}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <LinkButton
                                        fontSize="10px"
                                        onClick={() => handleProductCost(rowData)}
                                    >
                                        Ver
                                    </LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                {/* <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.outros}
                                    {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)}
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <div className={styles['neutro-indicator']}>
                                        {rowData.outrosInd}
                                        %
                                    </div>
                                    <LinkButton fontSize="10px">Editar</LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column> */}
                <Column sortable flexGrow={1}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={PMZComercialCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                {/* <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.pmzInd}
                                    %
                                </div> */}
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>base</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData?.tabelaAtual.tabelaBase}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <span className={styles['currency-indicator']}>
                                    $
                                    {' '}
                                    {tabelaBaseComercialPercentage(rowData)}
                                    %
                                </span>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>atual</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={Math.floor(rowData?.tabelaAtual.tabelaAtual * 100) / 100}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <span className={styles['currency-indicator']}>
                                    $
                                    {' '}
                                    {tabelaAtualComercialPercentage(rowData)}
                                    %
                                </span>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Novo preço</p>
                            <p>de tabela</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="novoPrecoTabela"
                        className={[styles['custo-fabricacao-cell'], styles.line].join(' ')}
                    >
                        {(rowData) => (
                            <ContextMenu>
                                <ContextMenuTrigger>
                                    <div>
                                        <InputGroup
                                            className={styles[
                                                statusPriceLimit(rowData, comercialTablePriceLimitAndRules.limites?.[0])
                                                    ? 'input-group-alert' : 'input-group'
                                            ]}
                                        >
                                            <InputGroup.Addon>R$</InputGroup.Addon>
                                            <InputCurrency
                                                className={styles['currency-input']}
                                                decimalSeparator=","
                                                thousandSeparator='.'
                                                value={Math.floor(rowData.parametros.novoPrecoTabela.valorNovo * 100) / 100}
                                                onBlur={(e) => onBlurNovoPrecoTabela(e, rowData)}
                                            />
                                        </InputGroup>
                                        <div className={styles.flex}>
                                            <Indicator
                                                fontSize="10px"
                                                value={comercialPrecoEditadoEPrecoTabelaAtualDiffCalc(rowData)}
                                            />
                                            <div className={styles['edit-indicator']}>{rowData.parametros.novoPrecoTabela.statusPreco}</div>
                                        </div>

                                    </div>
                                </ContextMenuTrigger>
                                <NewPriceTableInputContextMenu
                                    onClickPriceLimit={() => handleOpenModalPriceLimit(rowData)}
                                />
                            </ContextMenu>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Preço médio</p>
                            <p>projetado</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={comercialPrecoMedioProjetadoCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <LinkButton
                                        fontSize="10px"
                                        onClick={() => handleOnClickDetails(rowData)}
                                    >
                                        Detalhes
                                    </LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem</p>
                            <p>bruta</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto" >
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={comercialMargemBrutaCalc(rowData)}
                                        displayType={'text'}
                                        suffix={'%'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles.flex}>
                                    <Indicator
                                        className={styles['margem-indicator']}
                                        fontSize="10px"
                                        value={variacaoMargemBruta(rowData)}
                                        // value={margemBrutaPercentVariationCalc(rowData)}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem</p>
                            <p>operacional</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={comercialMargemOperacionalCalc(rowData)}
                                        displayType={'text'}
                                        suffix={'%'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles.flex}>
                                    <Indicator
                                        className={styles['margem-indicator']}
                                        fontSize="10px"
                                        value={variacaoMargemOperacionalCalc(rowData)}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={68}>
                    <HeaderCell className={styles.line}>
                        Promo
                    </HeaderCell>
                    <Cell
                        dataKey="promo"
                        className={[styles.line, styles['promo-cell']].join(' ')}
                    >
                        {(rowData) => (
                            <Button
                                className={styles[rowId.some((item) => item === rowData.id) ? 'btn-promo-selected' : 'btn-promo']}
                                onClick={() => handleOnClickPromo(rowData)}
                            >
                                <LocalOfferIcon />
                                <p className={styles['promo-unselected-text']}>
                                    Ativar
                                </p>
                                <p className={styles['promo-unselected-text']}>
                                    promo
                                </p>
                            </Button>
                            // <Button className={styles['btn-promo-selected']}>
                            //     <LocalOfferIcon />
                            //     <p className={styles['promo-selected-text']}>
                            //         Promo até
                            //     </p>
                            //     <p className={styles['promo-selected-text']}>
                            //         21/05/2024
                            //     </p>
                            // </Button>
                            )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                activePage={data?.page.number + 1 || null}
                displayLength={data?.page.size}
                total={data?.page.totalElements || null}
                onChangePage={onChangePage}
                onChangeLength={onChangeLength}
                lengthMenu={lengthMenu}
            />
        </div>

    );
}
