/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { Buffer } from 'buffer';
import { AlertWithTimestamp } from '../components/AlertWithTimestamp';
import history from './History';
import { trackEvent } from './MatomoConfig';
const api = axios.create({
    responseType: 'json',
});

api.interceptors.request.use(
    (config) => {
        config.headers = {
            Accept: config.download ? 'application/octet-stream' : 'application/json',
            Authorization: config.token
                ? `${config.uploadToken ? '' : 'Bearer '}${config.token}`
                : config.expectToken
                    ? `Bearer ${localStorage.getItem('autenticacao.token')}`
                    : config.uploadToken
                        ? localStorage.getItem('autenticacao.token')
                        : 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
            'Content-Type': config.headers.contentType
                ? config.headers.contentType
                : 'application/json; charset=utf-8',
        };
        return config;
    },
    (error) => Promise.reject(error),
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        const response = error?.response;
        const status = error?.response?.status;
        const url = error?.config?.url;

        if (response && status === 400) {
            let res;
            if (
                response.request.responseType === 'arraybuffer'
                && response.data.toString() === '[object ArrayBuffer]'
            ) {
                res = JSON.parse(
                    Buffer.from(response.data).toString('utf8'),
                );
            } else {
                res = response.data;
            }
            AlertWithTimestamp({ message: res.error_description || res.message || res[0]?.message || res?._embedded?.errors[0]?.message });
        }
        if (response && status === 401) {
            if (
                window.location.pathname !== '/login'
                && window.location.pathname !== '/nova-senha'
                && window.location.pathname !== '/recuperar-senha'
                && window.location.pathname !== '/criar-conta'
                && !window.location.pathname.includes('/free')
            ) {
                history.push('/login');
            }
        }
        if (response && status === 403) {
            if (response?.data?.message) {
                AlertWithTimestamp({ message: response.data.message });
            }
        }
        if (status === 404) {
            if (response.config.url.includes('infopanel-competitivity-engine') || response.config.url.includes('infopanel-industry-competitivity-engine')) {
                return;
            }
            AlertWithTimestamp({ message: 'Erro inesperado. Tente novamente' });
            trackEvent('Erro', status, url);
        }
        if (response && status === 412) {

            AlertWithTimestamp({ message: response.data.error_description || response.data.message || response.data.mensagem || response.data[0].message });

            return Promise.reject(error);
        }
        if (response && status === 500) {
            AlertWithTimestamp({ message: response?.data?.message ? response?.data?.message : 'Instabilidade no sistema. Tente novamente mais tarde' });
            trackEvent('Erro', status, url);
        }
        if (!response) {
            return Promise.reject(error);
        }
        if (response?.data?.status) {
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);

export default api;
