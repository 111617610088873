import { AxiosResponse } from 'axios';
import { addDays, format } from 'date-fns';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { CompetitorPriceDTO, CompetitorsInfo, IGerenciadorPrecosDatapoint as RowData, UnknownObject } from '../../../@types';
import { IPAMaths, api } from '../../../lib';
import { RuleModel } from '../Estrategia/pages/RegrasDePreco/lib';
import { GET_ALL_PRODUCTS, GET_COMPETITORS_INFO, GET_COMPETITORS_INFO_OFFER_PRICE } from './utils';
import { getValidData } from './utils/getValidData';

export type LoadFilterDataOption = {
    isModal?: boolean;
    size?: number;
};

export const loadFilterDataOptionDefault: LoadFilterDataOption = {
    isModal: false,
    size: 20,
};

export const salvarSimulacao = (data: any) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/simulation/forecast/save`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const novoCalculoPrevisao = (currentPrice: any, newPrice: any, storeId: any, productId: any, pmz: any) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/simulation/forecast`,
            method: 'GET',
            expectToken: true,
            params: {
                currentPrice,
                newPrice,
                storeId,
                productId,
                pmz,
            },
        }).then((resp) => resp),
    );

export type ApplyPricesParams = {
    selectedAllProducts: boolean;
    applyToFamily: boolean;
    productsSelect: {
        productId: RowData['productId'];
        storeId: RowData['storeId'];
        price: RowData['price'];
    }[];
    productsUnSelect: {
        productId: RowData['productId'];
        storeId: RowData['storeId'];
    }[];
    filters?: UnknownObject;
    datapointExhibitionType: 'PRODUCT' | 'FAMILY';
};

export const applyPrices = async (params: ApplyPricesParams) => {
    const res = await trackPromise(
        api.patch('/all-products/apply', params, {
            expectToken: true,
        }),
        'get-datapoints-revisao',
    );

    return res;
};

export const getPriceComposition = ({ productId, storeId }: any) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/price/composition`,
            method: 'GET',
            expectToken: true,
            params: getValidData({
                productId,
                storeId,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((response) => response.data),
        'price-composition',
    );

export type IGetCompetitorsInfo = {
    productId: RowData['productId'];
    storeId: RowData['storeId'];
    productsToBePricedId: RowData['productsToBePricedId'];
    competitorDays: RowData['competitorDays'];
    productFamilyId?: RowData['productFamilyId'];
    competitorStoreId?: CompetitorPriceDTO['competitorStoreId'][];
    unselectedCompetitors: RuleModel['competitors'];
    clusterId: string | null;
};

export namespace GetCompetitorsInfo {
    export type Params = IGetCompetitorsInfo;
    export type Result = CompetitorsInfo;
}

export const getCompetitorsInfo = async (params: GetCompetitorsInfo.Params): Promise<GetCompetitorsInfo.Result> => {
    const { url, config } = GET_COMPETITORS_INFO(params);

    const { data } = await trackPromise(api.get<CompetitorsInfo>(url, config), `competitor-row-${params.storeId}-${params.productId}`);

    return data;
};

export const getCompetitorsInfoOfferPrice = async (params: GetCompetitorsInfo.Params): Promise<GetCompetitorsInfo.Result> => {
    const { url, config } = GET_COMPETITORS_INFO_OFFER_PRICE(params);

    const { data } = await trackPromise(api.get<CompetitorsInfo>(url, config), `competitor-row-${params.storeId}-${params.productId}`);

    return data;
};

export const getFamiliaProdutos = (productFamilyId = '', { size = 20, isModal = false }: LoadFilterDataOption = loadFilterDataOptionDefault) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/families/id`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(productFamilyId && {
                    productFamilyId,
                    size,
                    isModal,
                }),
            },
        }).then((resp) => resp?.data),
    );

export const getLojasRevisao = (search: any) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/store`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(search && {
                    search,
                }),
            },
        }).then((resp) => resp?.data),
    );

export namespace GetDatapointsRevisao {
    export type Params = UnknownObject;
    export type Response = {
        content: RowData[];
        totalElements: number;
    };
}

export const onSuccessGetDatapointsRevisaoItem = (rowData: RowData): RowData => {
    const { price, competitorsPrice, retailPrice, newMargin, wholesale, offer } = rowData;

    const novaCompetitividade = IPAMaths.novaCompetitividade(price, competitorsPrice);

    const retailCpi = IPAMaths.novaCompetitividade(retailPrice, competitorsPrice);

    const wholesaleCpi = IPAMaths.precoWithCpi(wholesale?.price ?? null, competitorsPrice);

    const offerCpi = IPAMaths.novaCompetitividade(offer?.price ?? null, offer?.competitorsPrice ?? null);

    return {
        ...rowData,
        expirationDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        revenueForecastSevenDays: 13000,
        novaMargem: newMargin,
        novaCompetitividade,
        retailCpi,
        ...(wholesale && {
            wholesale: {
                ...wholesale,
                newCompetitivenessPrice: wholesaleCpi,
                discountPercentage: wholesale.discountPercentage,
            },
        }),
        ...(offer && {
            offer: {
                ...offer,
                newCompetitivenessPrice: offerCpi,
                discountPercentage: offer.discountPercentage,
            },
        }),
    };
};

export const onSuccessGetDatapointsRevisao = (data: GetDatapointsRevisao.Response): GetDatapointsRevisao.Response => {
    return {
        ...data,
        content: data.content.map((datapoint) => {
            return onSuccessGetDatapointsRevisaoItem(datapoint);
        }),
    };
};

export const getDatapointsRevisao = async (params: GetDatapointsRevisao.Params): Promise<GetDatapointsRevisao.Response> => {
    const { url, config } = GET_ALL_PRODUCTS(params);
    const { data } = await trackPromise(
        api.post<GetDatapointsRevisao.Response>(url, config.data, {
            expectToken: true,
            params: {
                sort: (config.params as { sort?: unknown; size?: unknown; page?: unknown }).sort,
                size: (config.params as { sort?: unknown; size?: unknown; page?: unknown }).size,
                page: (config.params as { sort?: unknown; size?: unknown; page?: unknown }).page,
            },
        }),
        'get-datapoints-revisao',
    );
    return onSuccessGetDatapointsRevisao(data);
};

export type SaveEditedDatapoint = {
    data: Pick<RowData, 'productId' | 'storeId' | 'price' | 'productsToBePricedId'> & {
        datapointExhibitionType: string;
    };
    response: AxiosResponse<RowData[] & { datapointExhibitionType: string }>;
};

export function saveEditedDatapoint({ price, datapointExhibitionType, ...data }: SaveEditedDatapoint['data']): Promise<SaveEditedDatapoint['response']> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/update/price`,
            method: 'POST',
            expectToken: true,
            params: getValidData({
                editedPrice: price,
                datapointExhibitionType,
                ...data,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        })
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                throw error;
            }),
        `edit-datapoint-${data.productId}-${data.storeId}`,
    );
}

export namespace SaveEditedWholesaleDatapoint {
    export type Data = {
        productId: RowData['productId'];
        storeId: RowData['storeId'];
        price: RowData['price'];
        triggerCount: number | null;
    };
    export type Result = AxiosResponse<unknown>;
}

export const saveEditedWholesaleDatapoint = async (data: SaveEditedWholesaleDatapoint.Data): Promise<SaveEditedWholesaleDatapoint.Result> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { price, ...rest } = data;
    const updatedData = { ...rest, editedPrice: data.price };
    return api.post('/all-products/update/price/wholesale', updatedData, {
        expectToken: true,
    });
};

export namespace SaveEditedOfferDatapoint {
    export type Data = {
        productId: RowData['productId'];
        storeId: RowData['storeId'];
        price: RowData['price'];
    };
    export type Result = AxiosResponse<unknown>;
}

export const saveEditedOfferDatapoint = async (data: SaveEditedOfferDatapoint.Data): Promise<SaveEditedOfferDatapoint.Result> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { price, ...rest } = data;
    const updatedData = { ...rest, editedPrice: data.price };
    return api.post('/all-products/update/price/offer', updatedData, {
        expectToken: true,
    });
};

export namespace UndoEditedDatapoint {
    export type Params = {
        productId: RowData['productId'];
        storeId: RowData['storeId'];
        productsToBePricedId: RowData['productsToBePricedId'];
    };
    export type Response = AxiosResponse<RowData>;
}

export function undoEditedDatapoint(params: UndoEditedDatapoint.Params) {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/undo-price-change`,
            method: 'DELETE',
            expectToken: true,
            params,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp),
        `edit-datapoint-${params.productId}-${params.storeId}`,
    );
}

export function getTotalPrices(data: any) {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/total-prices`,
            method: 'POST',
            data: data,
            expectToken: true,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'comma' });
            },
        }).then((resp) => resp),
        'total-price',
    );
}

export function getVariationPrices(data: any) {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/variation-price`,
            // method: 'GET',
            // params: data,
            method: 'POST',
            expectToken: true,
            data: data,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'comma' });
            },
        }).then((resp) => resp),
        'variation-price',
    );
}
