import React, { useState } from 'react';
import { Input } from 'rsuite';

import classNames from 'classnames';
import { InputProps } from 'rsuite/lib/Input';

interface CustomNumberInputProps extends InputProps {
    onChangeValue: (value: string) => void;
    value: string;
    allowNegative?: boolean;
    className?: string;
}

enum Constants {
    DEFAULT_VALUE = '0,0',
    NEGATIVE_SIGN = '-',
}

enum Key {
    BACKSPACE = 'Backspace',
    DELETE = 'Delete',
}

const formatValue = (value: string | number) => {
    if (typeof value === 'string') return value.replace('.', ',');
    if (typeof value === 'number') return value.toFixed(1).replace('.', ',');
    return Constants.DEFAULT_VALUE;
};

const CustomNumberInput = React.forwardRef<HTMLInputElement, CustomNumberInputProps>(
    ({ className, value, onChangeValue, allowNegative = false, ...props }, ref) => {
        const [internalValue, setInternalValue] = useState(() => formatValue(value));

        const handleChange = (inputValue: any) => {
            const isNegative = inputValue.includes(Constants.NEGATIVE_SIGN);
            if (!allowNegative && isNegative) return;

            const cleanValue = inputValue.replace(/[^0-9]/g, '');

            if (cleanValue === '') {
                setInternalValue(isNegative ? `-${Constants.DEFAULT_VALUE}` : Constants.DEFAULT_VALUE);
                onChangeValue(isNegative ? `-${Constants.DEFAULT_VALUE}` : Constants.DEFAULT_VALUE);

                return;
            }

            let integerPart = '';
            let decimalPart = '0';

            if (cleanValue.length === 1) {
                integerPart = cleanValue[0];
            } else {
                integerPart = cleanValue.slice(0, -1);
                decimalPart = cleanValue.slice(-1);
            }

            const formatted = `${isNegative ? '-' : ''}${parseInt(integerPart || '0', 10)},${decimalPart}`;
            setInternalValue(formatted);
            onChangeValue(formatted);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!allowNegative && e.key === Constants.NEGATIVE_SIGN) return;

            const isDeleting = [Key.BACKSPACE, Key.DELETE].includes(e.key as Key);

            if (isDeleting) {
                e.preventDefault();
                const { value } = e.currentTarget;
                const isNegative = value.includes(Constants.NEGATIVE_SIGN);
                const parts = value.split(',');
                const integerPart = parts[0].slice(0, -1);
                const decimalPart = parts[0].slice(-1);
                const cleanValue = integerPart.replace(/[^0-9]/g, '') || '0';

                const isEqualToZero = cleanValue === '0' && decimalPart === '0';
                const newValue = isEqualToZero
                    ? Constants.DEFAULT_VALUE
                    : `${isNegative ? Constants.NEGATIVE_SIGN : ''}${parseInt(cleanValue, 10)},${decimalPart}`;

                setInternalValue(newValue);
                onChangeValue(newValue);
            }
        };

        return <Input {...props} inputRef={ref} value={internalValue} onChange={handleChange} onKeyDown={handleKeyDown} className={classNames(className)} />;
    },
);

CustomNumberInput.displayName = 'CustomNumberInput';

export default CustomNumberInput;
