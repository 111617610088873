import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Content, Icon, Input, Notification } from 'rsuite';
import { deleteSavedReportItem, getSavedReportList, setGenerateReportResponse, setStateValue } from '../../../../../actions/actionsModuloRelatorio';
import ModalConfirm from '../../../../../components/ModalConfirm';
import SavedReportTable from '../Components/SavedReportTable';
import { fileDownloadStatus } from '../data';
import { checkWithin90Days } from '../utils';

import iconCheckGreen from '../../../../../assets/icons/icon_check_circle_green.svg';
import { LoadingSpinerArea } from '../../../../../components/LoadingSpinner';
import { generateSavedReport } from '../services';

const SavedReportContent = ({ goToContent }) => {
    const history = useHistory();

    const [showModalDeleteSavedReport, setShowModalDeleteSavedReport] = useState(false);
    const [showModalProcessingReport, setShowModalProcessingReport] = useState(false);
    const [reportData, setReportData] = useState({ id: '', fileName: '' });

    const { savedReportData, savedTableLoading, deleteSavedReportSuccess, moduloRelatorioData } = useSelector(
        (state) => ({
            savedReportData: state.moduloRelatorioDataReducer?.savedReportData,
            savedTableLoading: state.moduloRelatorioDataReducer?.savedTableLoading,
            deleteSavedReportSuccess: state.moduloRelatorioDataReducer?.deleteSavedReportSuccess,
            moduloRelatorioData: state.moduloRelatorioDataReducer?.moduloRelatorioData,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const handleSearchSavedReport = (inputValue) => {
        dispatch(getSavedReportList({ page: 0, size: 10, query: inputValue }));
    };

    const handleChangePage = (value) => {
        dispatch(getSavedReportList({ page: value, size: 10, query: '' }));
    };

    const generateReport = (reportId) => {
        generateSavedReport(reportId)
            .then(() => {
                setShowModalProcessingReport(true);
            })
            .catch((err) => {
                dispatch(setGenerateReportResponse(err.response.status, true, err.response.status === 413 ? err.response.data.match(/\d+/g)[0] : '0'));
            });
    };

    const handleSelectThreeDotButtonOption = (key, rowData) => {
        const isWithin90Days = checkWithin90Days(rowData.reportEngineDTO.dataInicio) && checkWithin90Days(rowData.reportEngineDTO.dataFim);

        setReportData((prevState) => ({
            ...prevState,
            id: rowData.id,
            fileName: rowData.fileName,
        }));

        if (key === 1) {
            history.push(`/isa/modulo-relatorio/${rowData.id}`);
        }

        if (key === 2) {
            setShowModalDeleteSavedReport(true);
        }

        if (key === 3) {
            if (!isWithin90Days) {
                dispatch(setStateValue('openModalGenerateReportError', true));
                return;
            }
            generateReport(rowData.id);
        }
    };

    useEffect(() => {
        dispatch(getSavedReportList({ page: 0, size: 10, query: '' }));
    }, []);

    useEffect(() => {
        if (deleteSavedReportSuccess) {
            setShowModalDeleteSavedReport(false);

            Notification.open({
                className: 'product-config-success-notification',
                description: (
                    <>
                        <img src={iconCheckGreen} alt="" />
                        <p>Relatório removido com sucesso.</p>
                    </>
                ),
            });
            dispatch(setStateValue('deleteSavedReportSuccess', false));
        }
    }, [deleteSavedReportSuccess]);

    return (
        <Content className="saved-report-content">
            <LoadingSpinerArea area="generate-report-now" size="md" />
            <div className="content-title-wrapper">
                <p className="content-title">Meus relatórios</p>
                <div className="input-search-wrapper">
                    <Icon icon="search" style={{ color: '#1473E6' }} />
                    <Input className="input-search-report" placeholder="Pesquise pelo nome do relatório" onChange={handleSearchSavedReport} />
                </div>
            </div>
            <SavedReportTable
                data={savedReportData}
                loading={savedTableLoading}
                setPage={handleChangePage}
                onSelectThreeDotButtonOption={handleSelectThreeDotButtonOption}
            />
            <ModalConfirm
                show={showModalProcessingReport}
                modalWidth="590px"
                titleFontSize="20px"
                textAlign="center"
                footerAlign="center"
                icon={fileDownloadStatus.showModalConfirmDownload.icon}
                closeButton={false}
                title={fileDownloadStatus.showModalConfirmDownload.title}
                message={fileDownloadStatus.showModalConfirmDownload.message}
                confirmButton={fileDownloadStatus.showModalConfirmDownload.confirmButton}
                cancelButton={fileDownloadStatus.showModalConfirmDownload.cancelButton}
                onCancel={() => goToContent('CRIAR_RELATORIO')}
                onConfirm={() => goToContent('HISTORICO_RELATORIOS')}
            />
            <ModalConfirm
                show={showModalDeleteSavedReport}
                modalWidth="408px"
                titleFontSize="20px"
                textAlign="center"
                footerAlign="center"
                closeButton={false}
                title="Excluir relatório salvo?"
                message={`Tem certeza que deseja excluir o relatório ${reportData?.fileName}?`}
                confirmButton="EXCLUIR"
                cancelButton="CANCELAR"
                onCancel={() => setShowModalDeleteSavedReport(false)}
                onConfirm={() => dispatch(deleteSavedReportItem(reportData?.id))}
            />
        </Content>
    );
};

export default SavedReportContent;
