import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { Button, Container, Icon, Nav } from 'rsuite';
import { getSavedReportItem, setGenerateReportResponse, setStateValue } from '../../../../actions/actionsModuloRelatorio';
import { setLimitProductsFiltrosData } from '../../../../actions/actionsPainelGeral';
import errorIcon from '../../../../assets/icons/icon_error_circle.svg';
import lockIcon from '../../../../assets/icons/icon_lock.svg';
import Header from '../../../../components/Header';
import ModalConfirm from '../../../../components/ModalConfirm';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import { trackEvent } from '../../../../utils/MatomoConfig';
import { sendLead } from '../InfoPanel/utils';
import EditSavedReportForm from './Components/EditSavedReportForm';
import HowToUseModuleReportBanner from './Components/HowToUseModuleReportBanner';
import CreateReportContent from './Content/CreateReportContent';
import HistoryReportContent from './Content/HistoryReportContent';
import SavedReportContent from './Content/SavedReportContent';
import { fileDownloadStatus } from './data';
import { dataVideoModuleReportModal } from './utils';

const ModuloRelatorio = () => {
    const [tab, setTab] = useState('CRIAR_RELATORIO');
    const [showCTA, setShowCTA] = useState(false);

    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const { clienteData, savedReportContent, showModalNoDownload, openModalGenerateReportError } = useSelector(
        (state) => ({
            clienteData: state.clienteDataReducer?.clienteData,
            savedReportContent: state.moduloRelatorioDataReducer?.savedReportContent,
            showModalNoDownload: state.moduloRelatorioDataReducer?.showModalNoDownload,
            openModalGenerateReportError: state.moduloRelatorioDataReducer?.openModalGenerateReportError,
        }),
        shallowEqual,
    );

    const hasNoPermission = !clienteData?.tipoAdicionalContratos?.PAN?.includes('MODULO_RELATORIO');

    const sendCTA = () => {
        sendLead(clienteData, 'upsell-relatorio');
        trackEvent('Upsell/Crossell', 'upsell-block-relatorios');
        setShowCTA(false);
        window.open('https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel');
    };

    const handleContentChange = (value) => {
        setTab(value);
        dispatch(setStateValue('savedReportContent', false));
    };

    const handleGoBackToSavedReportContent = () => {
        history.replace('/isa/modulo-relatorio');
        dispatch(setStateValue('savedReportContent', true));
    };

    const setModalMessage = () => {
        const completeMessage = `
            Esse relatório irá gerar mais de 1 milhão de linhas.
        `;

        return completeMessage;
    };

    useEffect(() => {
        if (savedReportContent) {
            setTab('RELATORIOS_SALVOS');
        }
    }, [savedReportContent]);

    useEffect(() => {
        if (typeof id === 'string') {
            dispatch(getSavedReportItem(id));
            dispatch(setStateValue('savedReportContent', false));
            setTab('CRIAR_RELATORIO');
        }
    }, [id]);

    useEffect(() => {
        const userIsFlex = clienteData?.servicoPanel?.pacotePainel?.name === 'FLEX';

        if (userIsFlex) {
            dispatch(setLimitProductsFiltrosData(userIsFlex));
        }
    }, [clienteData?.servicoPanel?.pacotePainel?.name]);

    const setContent = {
        CRIAR_RELATORIO: <CreateReportContent goToContent={setTab} onClickGoBack={handleGoBackToSavedReportContent} />,
        HISTORICO_RELATORIOS: <HistoryReportContent />,
        RELATORIOS_SALVOS: <SavedReportContent goToContent={setTab} />,
    };

    return (
        <Container className="main-container modulo-relatorio">
            <div id="container">
                {typeof id === 'string' ? (
                    <EditSavedReportForm onClickGoBack={handleGoBackToSavedReportContent} />
                ) : (
                    <Header
                        productTitle="InfoPanel"
                        title="Módulo de Relatório"
                        subtitle="Construa relatórios personalizados com os dados do InfoPanel"
                        badgeLabel="Pro"
                        icon={<Icon icon="building2" />}
                    />
                )}
                {hasNoPermission ? (
                    <HowToUseModuleReportBanner
                        dataVideo={dataVideoModuleReportModal}
                        title={
                            <>
                                <p>Conheça o Módulo</p>
                                <p>de Relatórios</p>
                            </>
                        }
                    />
                ) : null}
                <div className="content-area">
                    {typeof id === 'string' ? null : (
                        <Nav activeKey={tab} appearance="subtle" onSelect={(eventKey) => handleContentChange(eventKey)}>
                            <Nav.Item eventKey="CRIAR_RELATORIO">Criar relatório</Nav.Item>
                            <Nav.Item eventKey="RELATORIOS_SALVOS">Relatórios salvos</Nav.Item>
                            <Nav.Item eventKey="HISTORICO_RELATORIOS">Histórico de relatórios</Nav.Item>
                        </Nav>
                    )}
                    {setContent[tab]}
                    {hasNoPermission ? (
                        <div className="block-plan">
                            <div className="block-plan-icon-circle">
                                <img src={lockIcon} alt="" />
                            </div>
                            <p>Você não tem acesso a essa funcionalidade.</p>
                            <p>Entre em contato com o nosso time para ativá-la.</p>
                            <Button appearance="primary" onClick={() => setShowCTA(true)}>
                                Quero saber mais
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={sendCTA}
                onCancel={() => {
                    setShowCTA(false);
                }}
            />
            <ModalConfirm
                className="modal-generate-report-error"
                show={openModalGenerateReportError}
                icon={errorIcon}
                modalWidth={480}
                textAlign="center"
                footerAlign="center"
                title="Não foi possível gerar o seu relatório pois as datas salvas ultrapassam os últimos 90 dias."
                message=" Para garantir que seu relatório seja gerado com sucesso, edite escolhendo um período dentro dos últimos 90 dias, salve e gere seu relatório novamente."
                confirmButton="Fechar"
                onConfirm={() => dispatch(setStateValue('openModalGenerateReportError', false))}
                onHideHeaderButton={() => dispatch(setStateValue('openModalGenerateReportError', false))}
            />
            <ModalConfirm
                show={showModalNoDownload !== false}
                modalWidth="490px"
                titleFontSize="16px"
                textAlign="center"
                footerAlign="center"
                closeButton={fileDownloadStatus[showModalNoDownload]?.closeButton}
                cancelButtonWidth="200px"
                confirmButtonWidth="200px"
                icon={fileDownloadStatus[showModalNoDownload]?.icon}
                title={fileDownloadStatus[showModalNoDownload]?.title}
                message={fileDownloadStatus[showModalNoDownload]?.message}
                upMessage={setModalMessage()}
                confirmButton={fileDownloadStatus[showModalNoDownload]?.confirmButton}
                cancelButton={fileDownloadStatus[showModalNoDownload]?.cancelButton}
                onCancel={() => dispatch(setGenerateReportResponse(false))}
                onConfirm={() => dispatch(setGenerateReportResponse(false))}
            />
        </Container>
    );
};

export default withRouter(ModuloRelatorio);
