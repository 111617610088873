import React from 'react';
import {
    Checkbox,
    Table,
    Tooltip,
    Whisper
} from 'rsuite';
// import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
// import {
//     setFiltersFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
// import {
//     setInputFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
// import {
//     setResultFromEditScenario,
//     setResultScenarioData,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { LinkButton } from '../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';

import CurrencyFormat from 'react-currency-format';
import { applyDiscount, descontoMaximoTagCalc, margemOperacionalTagCalc, PMZComercialCalc, tabelaAtualComercialPercentage } from '../../../../calculos';
import styles from './RetailConsultCurrentTable.module.scss';
import { useManageComercialTableStore } from '../../../ManageComercialTable/ManageComercialTable.store';
import { useConsultComercialTableStore } from '../../ConsultComercialTable.store';

type RetailConsultCurrentTableProps = {
    data?: any,
    onChangeCheckbox?: (rowData: any, checked: boolean) => void,
}

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

export const RetailConsultCurrentTable = ({data, onChangeCheckbox}: RetailConsultCurrentTableProps) => {
    // const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;
    // const history = useHistory();
    const { comercialTablePriceLimitAndRules } = useManageComercialTableStore();
    const { consultComercialProductItemSelectedList } = useConsultComercialTableStore();

    const regras = comercialTablePriceLimitAndRules.regras ? comercialTablePriceLimitAndRules.regras[0] : {}

    return (
        <div className={styles['table-consult-current-container']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={data?.data?.content}
                autoHeight
                id="consultar-tabela-comercial"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox
                                checked={
                                    consultComercialProductItemSelectedList.some((item) =>
                                        item.produto.codigoInternoProduto === rowData?.produto.codigoInternoProduto)
                                }
                                value={rowData?.produto.codigoInternoProduto}
                                onChange={(v, checked) => onChangeCheckbox?.(rowData, checked)}
                            />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.produto.descricaoProduto}</Tooltip>}
                            >
                                <div>
                                    <p className={styles['main-bold-text']}>{rowData.produto.codigoInternoProduto}</p>
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto.descricaoProduto}
                                    </p>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Família
                    </HeaderCell>
                    <Cell dataKey="familia" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['regular-text-family']}>{rowData.produto.categoriaNivel1}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>UF</HeaderCell>
                    <Cell dataKey="uf">
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['regular-text']}>
                                    {rowData.produtoComercial.uf}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custo</p>
                            <p>fabricação</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="custoFabricacao"
                        className={styles.line}
                    >
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.produtoComercial.parametros.custoFabricacao}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div className={styles['main-bold-text']}>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.produto.mediaCustoProducao}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                {/* <div className={styles['outros-indicator-flex']}>
                                    <div className={styles['neutro-indicator']}>
                                        {rowData.outrosInd}
                                        %
                                    </div>
                                </div> */}
                                    <LinkButton fontSize="10px">Editar</LinkButton>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={PMZComercialCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Preço</p>
                            <p>tabela</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.produtoComercial.parametros.precoTabela || 0}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <p className={styles.subtext}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={tabelaAtualComercialPercentage(rowData)}
                                        displayType={'text'}
                                        prefix={'$ '}
                                        suffix='%'
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Desconto</p>
                            <p>máximo</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {regras.descontoMaximo}
                                    %
                                </p>

                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Preço c/</p>
                            <p>desconto</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={applyDiscount(regras.descontoMaximo, rowData.produtoComercial.parametros.precoTabela)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <p className={styles.subtext}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={descontoMaximoTagCalc(regras.descontoMaximo, rowData.produtoComercial.parametros.precoTabela)}
                                        displayType={'text'}
                                        prefix={'$ '}
                                        suffix='%'
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Preço</p>
                            <p>oferta</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {' '}
                                    {/* {rowData?.tabelaAtual} */}
                                    {/* {utils.formatLocaleNumber(rowData.outputs.new_price_scenario?.demand_forecast, 0)} */}
                                </p>

                                <p className={styles.subtext}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={
                                            margemOperacionalTagCalc(
                                                rowData.produtoComercial.parametros.precoMargemZero,
                                                rowData.produtoComercial.parametros.precoTabela
                                            )
                                        }
                                        displayType={'text'}
                                        prefix={'$ '}
                                        suffix='%'
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Desconto</p>
                            <p>oferta</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {/* {rowData?.tabelaAtual} */}
                                    {' '}
                                    %
                                </p>
                                <p className={styles.subtext}>
                                    R$
                                    {' '}
                                    {/* {rowData.npInd} */}
                                    {/* {utils.formatLocaleNumber(rowData.outputs.base_price_scenario?.demand_forecast, 0)} */}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Validade</p>
                            <p>oferta</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {/* {rowData?.tabelaAtual} */}
                                    {' '}
                                    %
                                </p>
                                <p className={styles.subtext}>
                                    R$
                                    {' '}
                                    {/* {rowData.npInd} */}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                // activePage={tableState.page + 1 || null}
                // displayLength={tableData.size}
                // total={tableData.totalElements || null}
                // onChangePage={tableHandlers.handleChangePage}
                // onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
